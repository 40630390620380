import React from 'react';
import moment from 'moment';
import { DATE_FORMAT_DD_MMM, DATE_FORMAT_DD_MMM_YYYY } from '../../helpers/common';
import { MyLink } from '../MyLink';
import { APP_LINKS } from '../../helpers/links';
import { prettyFloatMoney } from '../../helpers/utils';
import { CONTACT_EMAIL } from '../../helpers/constants';
import { canChangeUntil } from '../../helpers/date';

export const NOTIFICATIONS = {
  loan_disbursements_starts: end_date => idx =>
    (
      <li key={idx}>
        Loan disbursements starts{' '}
        <span>
          {moment(end_date).add(1, 'day').format(DATE_FORMAT_DD_MMM).replace('.', '')}, 00:00 GMT
        </span>
      </li>
    ),
  verify_identity_cofounder: () => idx =>
    (
      <li key={idx}>
        Verify your identity to confirm fundraising.{' '}
        <MyLink href={APP_LINKS.profile + '?r=veriff'} style={{ color: '#9E98A3' }}>
          Start verification process →
        </MyLink>
      </li>
    ),
  you_can_change_fundraising_settings: end_date => idx =>
    (
      <li key={idx}>
        You can change the fundraising settings until{' '}
        <span>{moment(end_date).format(DATE_FORMAT_DD_MMM).replace('.', '')}, 23:59 GMT</span>
      </li>
    ),
  your_loan_successfully_completed: raised => idx =>
    (
      <li key={idx}>
        Your loan {prettyFloatMoney(raised)} has been successfully completed.{' '}
        <MyLink href={APP_LINKS.loans} className="c-grey">
          See details →
        </MyLink>
      </li>
    ),
  origination_fee_charged: disbursment_fee => idx =>
    (
      <li key={idx}>
        Fee of {prettyFloatMoney(disbursment_fee)} was immediately charged.{' '}
        <MyLink href={APP_LINKS.activity} className="c-grey">
          See details →
        </MyLink>
      </li>
    ),
  not_enough_investor_to_fund: () => idx =>
    (
      <li key={idx}>
        There wasn&apos;t enough investor demand to fund your startup loan this time. Please reach
        out to Scramble support to learn what can be improved to succeed next time!
        <br />
        Email us at{' '}
        <MyLink className="email" isExternal newWindow={false} href={`mailto:${CONTACT_EMAIL}`}>
          {CONTACT_EMAIL}
        </MyLink>
      </li>
    ),
  next_montly_repayment: (repayment, deadline) => idx =>
    (
      <li key={idx}>
        Your next monthly repayment of {repayment} is due on{' '}
        {moment(deadline).format(DATE_FORMAT_DD_MMM_YYYY).replace('.', '')}.{' '}
        <MyLink href={APP_LINKS.nextPayment} className="c-grey">
          See repayment schedule →
        </MyLink>
      </li>
    ),
  withdrawals_only_available_to_companies: () => idx =>
    (
      <li key={idx}>
        Withdrawals only available to companies with valid accounts. To activate your Scramble cash
        account, please transfer at least €1 from your bank account.{' '}
        <MyLink href={APP_LINKS.addFunds} className="c-grey">
          Add funds →
        </MyLink>
      </li>
    ),
  round_results_being_processed: () => idx =>
    <li key={idx}>The results of the round are being processed, please wait a bit.</li>,
  goal_has_been_reached: goal => idx =>
    (
      <li key={idx}>
        Your fundraising goal {prettyFloatMoney(goal)} has been successfully reached.{' '}
        <MyLink href={APP_LINKS.loans} className="c-grey">
          See new loans →
        </MyLink>
      </li>
    ),
  set_fundraising_goal_until: start_date => idx =>
    <li key={idx}>Set fundraising goal until {canChangeUntil(start_date)}</li>,
  verify_phone: () => idx =>
    (
      <li key={idx}>
        Verify your phone number.{' '}
        <MyLink href={`${APP_LINKS.profile}?r=phone`} className="c-grey">
          Provide details →
        </MyLink>
      </li>
    ),
  bank_accounts_available_to_verified_users: () => idx =>
    (
      <li key={idx}>
        Bank accounts are only available to verified users.{' '}
        <MyLink href={APP_LINKS.profile} className="c-grey underline">
          Complete the verification process →
        </MyLink>
      </li>
    ),
};
